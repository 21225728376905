import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'

import { ScannerIndicatorArrow, ScannerIndicatorIcon, ScannerIndicatorWrapper } from './Scanner.styles'

const ScannerIndicator = ({ size = 'default', text }) => {
  const { t } = useTranslation()

  return (
    <ScannerIndicatorWrapper size={size}>
      {/*<ScannerIndicatorIcon>*/}
      {/*  <Icon name="barcode" />*/}
      {/*</ScannerIndicatorIcon>*/}
      {/*<h5>{text || t('orScanYourProduct')}</h5>*/}
      {/*<ScannerIndicatorArrow>*/}
      {/*  <Icon name="arrow-down" />*/}
      {/*</ScannerIndicatorArrow>*/}
    </ScannerIndicatorWrapper>
  )
}

export default ScannerIndicator
